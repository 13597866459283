<template>
  <cs-page-docs class="views-docs-help">
    <h1 class="title">常见问题</h1>
    <a-skeleton :loading="loading" active />
    <a-skeleton :loading="loading" active />
    <div
      class="item"
      :id="`docs-help-${item.id}`"
      v-for="(item, index) in source.question"
      :key="index"
    >
      <h1><span class="dock"></span>{{ item.title }}</h1>
      <div class="item-content">
        <div v-if="item.showAllContent">
          <p v-html="item.content"></p>
          <div class="text-end" style="border-top: 1px solid #eee">
            <a-button type="link" @click="loadMoreClick(item)">
              收起全文 <a-icon type="up" />
            </a-button>
          </div>
        </div>
        <p v-else>
          {{ item.desc }}
          <a-button type="link" @click="loadMoreClick(item)">
            阅读全文 <a-icon type="down" />
          </a-button>
        </p>
      </div>
    </div>
  </cs-page-docs>
</template>

<script>
export default {
  data() {
    return {
      source: {
        question: [],
      },
      loading: false,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const { id } = this.$route.query;
      this.loading = true;
      this.api.service
        .fpc_oapi_problem_selectProblemAll({}, {})
        .then(async (res) => {
          await this.api.toast(res, 0);
          const len = 150;
          this.source.question = res.data.map((ele) => {
            let desc = ele.content.replace(/<[^>]+>/g, "");
            if (desc.length > len) {
              desc = desc.substr(0, len) + "...";
            }
            ele["desc"] = desc;
            ele.showAllContent = ele.id == id;
            return ele;
          });
        })
        .finally(() => {
          this.loading = false;
          // 定位到当前查看项
          if (id) {
            this.$nextTick(function () {
              const ele = this.$el.querySelector(`#docs-help-${id}`);
              ele.classList.add("active");
              ele.scrollIntoView();
              document.documentElement.scrollTop -= 380;
            });
          }
        });
    },
    loadMoreClick(item) {
      item.showAllContent = !item.showAllContent;
    },
  },
};
</script>

<style lang="less" scoped>
.views-docs-help {
  .title {
    font-family: PingFangSC-Medium;
    font-size: 36px;
    color: #000;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 73px;
    border-bottom: 1px solid #f0f0f0;
  }

  .item {
    margin-bottom: 40px;

    h1 {
      display: flex;
      align-items: center;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #000;
      letter-spacing: 0;
      font-weight: bold;
    }

    h1::before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      background: #333;
      border-radius: 50%;
      margin-right: 10px;
    }
    .item-content {
      padding: 0 15px;
    }
  }
}
</style>